//邮件详情
.mail-detail {
  margin-top: 60px;
  margin-left: 50px;
  .mail-title {
    margin-left: 30px;
    padding-top: 5px;
    .mail-name {
      margin-top: 10px;
    }
  }
  .mail-content {
    // line-height: 50 px;
    // margin-left: 30px;
    color: #1E1E1E;
  }
}